<script>
import Swal from "sweetalert2";
import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      formIsValid: true,
      name: {
        val: "",
        isValid: true,
      },
      description: {
        val: "",
        isValid: true,
      },
      msg: "",
      permission: {
        appointment: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        appointmantChat: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        patient: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        patientChat: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        doctor: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        report: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        doctorAvailability: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        staff: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        staffUsers: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        staffUserRole: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        settings: {
          view: false,
          add: false,
          update: false,
          delete: false,
        },
        isValid: true
      },
      // =========================== Variables for select all checkbox of colums============================

      allViewCheck: false,
      allAddCheck: false,
      allUpdateCheck: false,
      allDeleteCheck: false,
    };
  },
  methods: {
    // =============================================Form Validation=========================================
    clearValidity(input) {
      this[input].isValid = true;
    },
    // =======================Validation for checkboxes start====================
    validateForm() {
      if (this.name.val === "") {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (this.description.val === "") {
        this.description.isValid = false;
        this.formIsValid = false;
      }
      if (
        !this.permission.appointmantChat.view &&
        !this.permission.appointmantChat.add &&
        !this.permission.appointmantChat.update &&
        !this.permission.appointmantChat.delete &&
        !this.permission.patientChat.view &&
        !this.permission.patientChat.add &&
        !this.permission.patientChat.update &&
        !this.permission.patientChat.delete &&
        !this.permission.doctorAvailability.view &&
        !this.permission.doctorAvailability.add &&
        !this.permission.doctorAvailability.update &&
        !this.permission.doctorAvailability.delete &&
        !this.permission.staffUsers.view &&
        !this.permission.staffUsers.add &&
        !this.permission.staffUsers.update &&
        !this.permission.staffUsers.delete &&
        !this.permission.staffUserRole.view &&
        !this.permission.staffUserRole.add &&
        !this.permission.staffUserRole.update &&
        !this.permission.staffUserRole.delete
      ) {
        this.permission.isValid = false
        this.formIsValid = false;
      } else {
        this.formIsValid = true;
      }
    },

    // =======================Validation for checkboxes End====================
    // =============================================To Get Last Added Role's Id=======================================
    async getRoles() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/role"
      );
      this.roles = response.data;
      const lastEl = this.roles[this.roles.length - 1];
      this.id = lastEl.id;
      this.successmsg(this.id);
    },
    // ======================================================Add Role Method==================================================
    async addRoles() {
      this.validateForm();
      if (!this.formIsValid) {
        this.errormsg();
        return;
      } else {
        var formdata = new FormData();
        formdata.append("name", this.name.val);
        formdata.append("permission", JSON.stringify(this.permission));
        formdata.append("description", this.description.val);

        const result = await axios.post(
          "https://api.doctosoft.com/index.php/api/role",
          formdata
        );

        this.msg = result.data;
        this.getRoles();
        this.$router.push("/admin/user-roles");
      }
    },
    errormsg() {
      if (this.name.val === "") {
        Swal.fire(
          "Invalid Form",
          "Please Enter Your Name and try again later",
          "error"
        );
      } else {
        Swal.fire(
          "Invalid Form",
          "Atleast Check one permission to proceed",
          "error"
        );
      }
    },
    successmsg() {
      Swal.fire(
        "Good job!",
        "Created new role successfully " ,
        "success"
      );
    },
    //========================================Check All Module By Permission================================
    checkAllView() {
      if (this.allViewCheck === false) {
        this.permission.appointment.view = true;
        this.permission.appointmantChat.view = true;
        this.permission.patient.view = true;
        this.permission.patientChat.view = true;
        this.permission.doctor.view = true;
        this.permission.doctorAvailability.view = true;
        this.permission.staff.view = true;
        this.permission.staffUsers.view = true;
        this.permission.staffUserRole.view = true;
        this.permission.settings.view = true;
        this.permission.report.view = true;
      } else {
        this.permission.appointment.view = false;
        this.permission.appointmantChat.view = false;
        this.permission.patient.view = false;
        this.permission.patientChat.view = false;
        this.permission.doctor.view = false;
        this.permission.doctorAvailability.view = false;
        this.permission.staff.view = false;
        this.permission.staffUsers.view = false;
        this.permission.staffUserRole.view = false;
        this.permission.settings.view = false;
        this.permission.report.view = false;
      }
    },
    checkAllAdd() {
      if (this.allAddCheck === false) {
        this.permission.appointment.add = true;
        this.permission.appointmantChat.add = true;
        this.permission.patient.add = true;
        this.permission.patientChat.add = true;
        this.permission.doctor.add = true;
        this.permission.doctorAvailability.add = true;
        this.permission.staff.add = true;
        this.permission.staffUsers.add = true;
        this.permission.staffUserRole.add = true;
        this.permission.settings.add = true;
      } else {
        this.permission.appointment.add = false;
        this.permission.appointmantChat.add = false;
        this.permission.patient.add = false;
        this.permission.patientChat.add = false;
        this.permission.doctor.add = false;
        this.permission.doctorAvailability.add = false;
        this.permission.staff.add = false;
        this.permission.staffUsers.add = false;
        this.permission.staffUserRole.add = false;
        this.permission.settings.add = false; 
      }
    },
    checkAllUpdate() {
      if (this.allUpdateCheck === false) {
        this.permission.appointment.update = true;
        this.permission.appointmantChat.update = true;
        this.permission.patient.update = true;
        this.permission.patientChat.update = true;
        this.permission.doctor.update = true;
        this.permission.doctorAvailability.update = true;
        this.permission.staff.update = true;
        this.permission.staffUsers.update = true;
        this.permission.staffUserRole.update = true;
        this.permission.settings.update = true;
      } else {
        this.permission.appointment.update = false;
        this.permission.appointmantChat.update = false;
        this.permission.patient.update = false;
        this.permission.patientChat.update = false;
        this.permission.doctor.update = false;
        this.permission.doctorAvailability.update = false;
        this.permission.staff.update = false;
        this.permission.staffUsers.update = false;
        this.permission.staffUserRole.update = false;
        this.permission.settings.update = false;
      }
    },
    checkAllDelete() {
      if (this.allDeleteCheck === false) {
        this.permission.appointment.delete = true;
        this.permission.appointmantChat.delete = true;
        this.permission.patient.delete = true;
        this.permission.patientChat.delete = true;
        this.permission.doctor.delete = true;
        this.permission.doctorAvailability.delete = true;
        this.permission.staff.delete = true;
        this.permission.staffUsers.delete = true;
        this.permission.staffUserRole.delete = true;
        this.permission.settings.delete = true;
      } else {
        this.permission.appointment.delete = false;
        this.permission.appointmantChat.delete = false;
        this.permission.patient.delete = false;
        this.permission.patientChat.delete = false;
        this.permission.doctor.delete = false;
        this.permission.doctorAvailability.delete = false;
        this.permission.staff.delete = false;
        this.permission.staffUsers.delete = false;
        this.permission.staffUserRole.delete = false;
        this.permission.settings.delete = false;
      }
    },

    //=====================================Appointment Module CheckBox Methods Start====================
    checkAppointmentsView() {
      if (this.permission.appointment.view === false) {
        this.permission.appointmantChat.view = true;
      } else {
        this.permission.appointmantChat.view = false;
      }
      this.allViewCheck = false;
    },
    checkAppointmentsAdd() {
      if (this.permission.appointment.add === false) {
        this.permission.appointmantChat.add = true;
      } else {
        this.permission.appointmantChat.add = false;
      }
      this.allAddCheck = false;
    },
    checkAppointmentsUpdate() {
      if (this.permission.appointment.update === false) {
        this.permission.appointmantChat.update = true;
        this.permission.appointment.isValid = true;
      } else {
        this.permission.appointmantChat.update = false;
      }
      this.allUpdateCheck = false;
    },
    checkAppointmentsDelete() {
      if (this.permission.appointment.delete === false) {
        this.permission.appointmantChat.delete = true;
      } else {
        this.permission.appointmantChat.delete = false;
      }
      this.allDeleteCheck = false;
    },
    uncheckAppointmentView() {
      this.allViewCheck = false;
      this.permission.appointment.view = false;
    },
    uncheckAppointmentAdd() {
      this.allAddCheck = false;
      this.permission.appointment.add = false;
    },
    uncheckAppointmentUpdate() {
      this.allUpdateCheck = false;
      this.permission.appointment.update = false;
    },
    uncheckAppointmentDelete() {
      this.allDeleteCheck = false;
      this.permission.appointment.delete = false;
    },
    //=====================================Patient Module CheckBox Methods Start========================
    checkPatientView() {
      if (this.permission.patient.view === false) {
        this.permission.patientChat.view = true;
      } else {
        this.permission.patientChat.view = false;
      }
      this.allViewCheck = false;
    },
    checkPatientAdd() {
      if (this.permission.patient.add === false) {
        this.permission.patientChat.add = true;
      } else {
        this.permission.patientChat.add = false;
      }
      this.allAddCheck = false;
    },
    checkPatientUpdate() {
      if (this.permission.patient.update === false) {
        this.permission.patientChat.update = true;
      } else {
        this.permission.patientChat.update = false;
      }
      this.allUpdateCheck = false;
    },
    checkPatientDelete() {
      if (this.permission.patient.delete === false) {
        this.permission.patientChat.delete = true;
      } else {
        this.permission.patientChat.delete = false;
      }
      this.allDeleteCheck = false;
    },
    uncheckPatientView() {
      this.allViewCheck = false;
      this.permission.patient.view = false;
    },
    uncheckPatientAdd() {
      this.allAddCheck = false;
      this.permission.patient.add = false;
    },
    uncheckPatientUpdate() {
      this.allUpdateCheck = false;
      this.permission.patient.update = false;
    },
    uncheckPatientDelete() {
      this.allDeleteCheck = false;
      this.permission.patient.delete = false;
    },
    //=====================================Doctor Module CheckBox Methods Start==========================
    checkDoctorView() {
      if (this.permission.doctor.view === false) {
        this.permission.doctorAvailability.view = true;
      } else {
        this.permission.doctorAvailability.view = false;
      }
      this.allViewCheck = false;
    },
    checkDoctorAdd() {
      if (this.permission.doctor.add === false) {
        this.permission.doctorAvailability.add = true;
      } else {
        this.permission.doctorAvailability.add = false;
      }
      this.allAddCheck = false;
    },
    checkDoctorUpdate() {
      if (this.permission.doctor.update === false) {
        this.permission.doctorAvailability.update = true;
      } else {
        this.permission.doctorAvailability.update = false;
      }
      this.allUpdateCheck = false;
    },
    checkDoctorDelete() {
      if (this.permission.doctor.delete === false) {
        this.permission.doctorAvailability.delete = true;
      } else {
        this.permission.doctorAvailability.delete = false;
      }
      this.allDeleteCheck = false;
    },
    uncheckDoctorView() {
      this.allViewCheck = false;
      this.permission.doctor.view = false;
    },
    uncheckDoctorAdd() {
      this.allAddCheck = false;
      this.permission.doctor.add = false;
    },
    uncheckDoctorUpdate() {
      this.allUpdateCheck = false;
      this.permission.doctor.update = false;
    },
    uncheckDoctorDelete() {
      this.allDeleteCheck = false;
      this.permission.doctor.delete = false;
    },

    //=====================================Staff Module CheckBox Methods Start==========================
    checkStaffView() {
      if (this.permission.staff.view === false) {
        this.permission.staffUsers.view = true;
        this.permission.staffUserRole.view = true;
      } else {
        this.permission.staffUsers.view = false;
        this.permission.staffUserRole.view = false;
      }
      this.allViewCheck = false;
    },

    checkStaffAdd() {
      if (this.permission.staff.add === false) {
        this.permission.staffUsers.add = true;
        this.permission.staffUserRole.add = true;
      } else {
        this.permission.staffUsers.add = false;
        this.permission.staffUserRole.add = false;
      }
      this.allAddCheck = false;
    },
    checkStaffUpdate() {
      if (this.permission.staff.update === false) {
        this.permission.staffUsers.update = true;
        this.permission.staffUserRole.update = true;
      } else {
        this.permission.staffUsers.update = false;
        this.permission.staffUserRole.update = false;
      }
      this.allUpdateCheck = false;
    },
    checkStaffDelete() {
      if (this.permission.staff.delete === false) {
        this.permission.staffUsers.delete = true;
        this.permission.staffUserRole.delete = true;
      } else {
        this.permission.staffUsers.delete = false;
        this.permission.staffUserRole.delete = false;
      }
      this.allDeleteCheck = false;
    },
    uncheckStaffView() {
      this.permission.staff.view = false;
      this.allViewCheck = false;
    },
    uncheckStaffAdd() {
      this.permission.staff.add = false;
      this.allAddCheck = false;
    },
    uncheckStaffUpdate() {
      this.permission.staff.update = false;
      this.allUpdateCheck = false;
    },
    uncheckStaffDelete() {
      this.permission.staff.delete = false;
      this.allDeleteCheck = false;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/user-roles')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/user-roles')">Cancel</b-button>
          <p :class="{ invalidInput: !this.permission.isValid }" v-if="!permission.isValid">Please Add atleast one
                permission to proceed</p>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addRoles">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addRoles">
            <div class="mb-3" :class="{ invalidInput: !name.isValid }">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="name.val" placeholder="Enter name"
                @blur="clearValidity('name')" />
            </div>

            <div class="mb-3" :class="{ invalidInput: !this.permission.isValid }">
              <label for="permissions" class="form-label">
                Permissions <span class="text-danger">*</span>
              </label>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col"><label for="modules">Modules</label></th>
                    <th scope="col"><label for="view">View</label></th>
                    <th scope="col"><label for="add">Add</label></th>
                    <th scope="col"><label for="update">Update</label></th>
                    <th scope="col"><label for="delete">Delete</label></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Check Column</th>
                    <td>
                      <input type="checkbox" name="view" @click="checkAllView" v-model="allViewCheck" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" @click="checkAllAdd" v-model="allAddCheck" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" @click="checkAllUpdate" v-model="allUpdateCheck" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" @click="checkAllDelete" v-model="allDeleteCheck" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="module-data">Appointments</th>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.appointment.view"
                        @click="checkAppointmentsView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.appointment.add"
                        @click="checkAppointmentsAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.appointment.update"
                        @click="checkAppointmentsUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.appointment.delete"
                        @click="checkAppointmentsDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">Chat</td>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.appointmantChat.view"
                        @click="uncheckAppointmentView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.appointmantChat.add"
                        @click="uncheckAppointmentAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.appointmantChat.update"
                        @click="uncheckAppointmentUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.appointmantChat.delete"
                        @click="uncheckAppointmentDelete" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Patient</th>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.patient.view" @click="checkPatientView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.patient.add" @click="checkPatientAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.patient.update"
                        @click="checkPatientUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.patient.delete"
                        @click="checkPatientDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">Chat</td>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.patientChat.view"
                        @click="uncheckPatientView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.patientChat.add" @click="uncheckPatientAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.patientChat.update"
                        @click="uncheckPatientUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.patientChat.delete"
                        @click="uncheckPatientDelete" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Doctor</th>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.doctor.view" @click="checkDoctorView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.doctor.add" @click="checkDoctorAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.doctor.update"
                        @click="checkDoctorUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.doctor.delete"
                        @click="checkDoctorDelete" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Staff</th>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.staff.view" @click="checkStaffView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.staff.add" @click="checkStaffAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.staff.update" @click="checkStaffUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.staff.delete" @click="checkStaffDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">Users</td>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.staffUsers.view" @click="uncheckStaffView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.staffUsers.add" @click="uncheckStaffAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.staffUsers.update"
                        @click="uncheckStaffUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.staffUsers.delete"
                        @click="uncheckStaffDelete" />
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">User Role</td>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.staffUserRole.view"
                        @click="uncheckStaffView" />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.staffUserRole.add" @click="uncheckStaffAdd" />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.staffUserRole.update"
                        @click="uncheckStaffUpdate" />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.staffUserRole.delete"
                        @click="uncheckStaffDelete" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="module-data">settings</th>
                    <td>
                      <input type="checkbox" name="view" v-model="permission.settings.view"
                        />
                    </td>
                    <td>
                      <input type="checkbox" name="add" v-model="permission.settings.add"
                       />
                    </td>
                    <td>
                      <input type="checkbox" name="update" v-model="permission.settings.update"
                     />
                    </td>
                    <td>
                      <input type="checkbox" name="delete" v-model="permission.settings.delete"
                         />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-3" :class="{ invalidInput: !description.isValid }">
              <label for="description" class="form-label">Description <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="description.val" placeholder="Enter description"
                @blur="clearValidity('description')" />
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style scoped>

.module-data {
  display: flex;
  align-items: center;
}

</style>
